import Loading from '@/components/Loading'
import AdminAuthHeaderContainer from '@/components/Navigation/Top/AdminAuthHeaderContainer'
import { Outlet, createFileRoute } from '@tanstack/react-router'
import { queryClient } from "@/lib/queryClient";
import { useRootStore } from "@/store/useRootStore";

export const Route = createFileRoute('/_authenticated/entity')({
	loader: async ({ params }) => {
		const { entityId } = params;
		const userData = await queryClient.getQueryData(['userData']);
		const establishment = userData?.establishments?.find((establishment) => establishment.entityId === entityId);
		useRootStore.setState({ establishment });
	},
	component: Layout,
	pendingComponent: () => <Loading fullScreen />,
})

function Layout() {
	const route = window.location.pathname;
	const hasTimer = route.includes('/timer');
	return (
		<>
			{!hasTimer && <AdminAuthHeaderContainer />}
			<Outlet />
		</>
	)
}
