import { useEffect, useRef, useState } from "react";
import html2canvas from 'html2canvas';

import { Button } from "@repo/ui/button";
import PaperVerticalFlyer from "@/components/Flyers/PaperVerticalFlyer";
import { Sheet, SheetContent, SheetTrigger } from "@repo/ui/sheet";
import ManageFlyerSheet from "@/components/Sheets/Flyer/Manage";
import QrCodeSection from "@/components/Flyers/QrCodeSection";
import Loading from "@/components/Loading";

const flyerSize = {
  paperVertical: {
    width: 935,
    height: 1210,
  },
}

const PrintoutFlyer = ({
  activity,
  customization = {
    overlay: {
      color: '#18181B',
      opacity: 80,
    },
    texture: 'none',
    primaryText: null,
    secondaryText: null,
  },
  establishment,
  event,
  game,
  scale = 0.5,
  type = 'paperVertical',
}) => {
  const printRef = useRef(null);
  const height = flyerSize[type].height * scale;
  const width = flyerSize[type].width * scale;

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      useCORS: true, // Enable cross-origin resource sharing
    });
    const dataUrl = canvas.toDataURL('image/png');

    // Create a download link
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'downloaded-image.png';
    link.click();
  };


  const [dataUrl, setDataUrl] = useState(null);
  useEffect(() => {
    const getQRCode = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      useCORS: true, // Enable cross-origin resource sharing
      });
      setDataUrl(canvas.toDataURL('image/png'));
    }
    getQRCode();
  }, [printRef.current]);

  const handleDownloadQRCode = async () => {
    

    // Create a download link
    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = 'downloaded-image.png';
    link.click();
  };

  const showFlyer = false;

  return (
    <Sheet>
      {showFlyer && <div className='group relative' style={{ height: `${height}px`, width: `${width}px` }}>
        <div className='absolute top-0 left-0 flex flex-col items-center justify-center gap-4 h-full w-full z-[70] opacity-0 transition-all group-hover:opacity-100'>
          <h2 className='text-3xl font-bold'>8.5" x 11" Flyer</h2>
        </div>
        <div className='absolute top-0 left-0 flex flex-col items-center justify-end gap-4 h-full w-full z-[70] p-8 opacity-0 transition-all group-hover:opacity-100'>
          <SheetTrigger>
            <Button variant='text'>Manage</Button>
          </SheetTrigger>
          <Button onClick={handleDownloadImage} variant='gradient-contained'>Download</Button>
        </div>
        <div className='absolute top-0 left-0 h-full w-full z-[60] bg-zinc-900/80 blur-sm opacity-0 transition-all group-hover:opacity-100' />
        <div className='absolute top-0 left-0 h-full w-full z-[-2] bg-zinc-900 blur-lg' />
        <div className='absolute top-0 left-0 h-full w-full transition-all group-hover:w-[105%] group-hover:h-[105%] group-hover:blur-[1px]'>
          <PaperVerticalFlyer
            activity={activity}
            customization={customization}
            establishment={establishment}
            event={event}
            game={game}
            scale={0.5}
          />
        </div>
        <div className='opacity-0'>
          <PaperVerticalFlyer
            activity={activity}
            customization={customization}
            establishment={establishment}
            event={event}
            game={game}
            height={1100}
            printRef={printRef}
            width={850}
          />
        </div>
      </div>}

      <div className='group relative h-[500px] w-[400px]'>
        
        <div className='absolute top-0 left-0 flex flex-col items-center justify-end gap-4 h-full w-full z-[70] p-8 opacity-0 transition-all group-hover:opacity-100'>
          
          <Button onClick={handleDownloadQRCode} variant='gradient-contained'>Download</Button>
        </div>
        <div className='absolute top-0 left-0 h-full w-full z-[60] bg-zinc-900/80 blur-sm opacity-0 transition-all group-hover:opacity-100' />
        <div className='absolute top-0 left-0 h-full w-full z-[-2] bg-zinc-900 blur-lg' />
        <div className='absolute top-0 left-0 h-full w-full transition-all group-hover:blur-[1px]'>
          {!dataUrl && <div className='flex items-center justify-center h-full w-full'><Loading /></div>}
          {dataUrl && <img src={dataUrl} alt='Flyer' className='h-full w-full object-scale-down' />}
          
        </div>
        <div className='opacity-0 fixed'>
          <QrCodeSection
            activity={activity}
            event={event}
            scale={1}
            printRef={printRef}
            withHeader={true}
            game={game}
          />
        </div>
      </div>
      <SheetContent>
        <ManageFlyerSheet
          activity={activity}
          event={event}
          flyerName='8.5" x 11" Flyer'
          game={game}
          type={type}
        />
      </SheetContent>
    </Sheet>
  )
}

export default PrintoutFlyer;
