import dayjs from "dayjs";

const FlyerHeader = ({ gameImage, event, activity, scaleSize, scale }) => {
  return (
    <div className='flex flex-col items-center w-full'>
      {gameImage && <div className={`${scaleSize(scale).gameLogo} flex items-center justify-center w-full pb-4`}>
        <img src={gameImage} className='h-full' alt="Game logo" />
      </div>}
      <div className='flex flex-col items-center w-full py-4'>
        <h4 
          className={`${scaleSize(scale)?.eventName} text-black font-bold text-3xl w-[90%] text-center uppercase`} 
          style={{ fontFamily: 'Roboto, sans-serif' }}
        >
          {event.name}
        </h4>
        <h2 
          className={`${scaleSize(scale)?.activityName} text-black text-base font-bold w-[90%] text-center mb-2 uppercase tracking-[1px]`}
        >
          {activity.name}
        </h2>
        <h4 
          className={`${scaleSize(scale)?.day} text-black text-lg font-black w-[90%] text-center uppercase tracking-[1px]`} 
          style={{ fontFamily: 'Roboto, sans-serif' }}
        >
          {`${dayjs(activity?.startsAt).format('dddd MMMM D')} @ ${dayjs(activity?.startsAt).format('h:mmA')}`}
        </h4>
        <h3 
          className={`${scaleSize(scale)?.time} text-black text-lg font-black w-[90%] text-center uppercase -mt-2`}
        >
          
        </h3>
      </div>
    </div>
  );
};

export default FlyerHeader; 