import { useEventStore } from '@/store/useEventStore';
import { useRootStore } from '@/store/useRootStore';
import { webAxiosInstance } from '@/utils/axios';
import { useQuery } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { eventQueryOptions } from '@/data/organization/queries';
import { AutoForward, Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@repo/ui/carousel';
import { cn } from '@/utils/cn';
import { Route } from '@/routes/__root';

const ELEMENTS_PER_PAGE = 8;

const IndividualTimer = ({ timer, isOnTimerPage = false }) => {
  const endTime = timer.endTime;
  const Ref = useRef(null);
  const containerRef = useRef(null);
  const timerDigitsRef = useRef(null);
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const [scale, setScale] = useState(1);

  const getTimeRemaining = (e) => {
    const total =
      Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor(
      (total / 1000 / 60) % 60
    );
    const hours = Math.floor(
      (total / 1000 / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  useEffect(() => {
    const updateScale = () => {
      if (!containerRef.current || !timerDigitsRef.current) return;

      const containerWidth = containerRef.current.offsetWidth;
      const containerHeight = containerRef.current.offsetHeight;
      const timerWidth = timerDigitsRef.current.offsetWidth;
      const timerHeight = timerDigitsRef.current.offsetHeight;

      // Calculate scale ratios for both width and height
      const scaleX = containerWidth / timerWidth;
      const scaleY = containerHeight / timerHeight;

      // Use the smaller scale to ensure timer fits in both dimensions
      const newScale = Math.min(scaleX, scaleY) * 0.8; // 0.8 to add some padding

      setScale(newScale);
    };

    // Update scale on mount and window resize
    updateScale();
    window.addEventListener('resize', updateScale);

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, [containerRef.current, timerDigitsRef.current]);

  const startTimer = useCallback((e) => {
    let { total, hours, minutes, seconds } =
      getTimeRemaining(e);
    if (total >= 0) {
      setTimerHours(hours);
      setTimerMinutes(minutes > 9 ? minutes : "0" + minutes);
      setTimerSeconds(seconds > 9 ? seconds : "0" + seconds);
    }
  }, []);

  const clearTimer = useCallback((e) => {

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  }, [startTimer]);

  const getDeadTime = useCallback(() => {
    let deadline = new Date(endTime);

    // This is where you need to adjust if
    // you entend to add more time
    return deadline;
  }, [endTime]);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [clearTimer, getDeadTime]);

  return <div ref={containerRef} className='relative text-base border border-zinc-100/10 rounded-lg p-4 w-full h-full flex flex-col items-center justify-center'>
    <div ref={timerDigitsRef} className='flex flex-col items-center justify-center absolute top-1/2 left-1/2' style={{ transform: `translate(-50%, -50%) scale(${scale})` }}>
      <div className='text-center text-lg'>
        {timer.name} - Pod: {timer.pod}
      </div>
      {Number(timerHours) === 0 && Number(timerMinutes) === 0 && Number(timerSeconds) === 0 ? (
        <div className='flex flex-col items-center justify-center h-1/2'>
          <div className='tracking-[3px] text-lg'>
            TIMER
          </div>
          <div className='tracking-[1px] text-lg'>
            COMPLETE
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center'>
          <div className='flex items-center justify-between gap-1'>
            {Number(timerHours) > 0 && (
              <>
                <div className='text-4xl'>{timerHours}</div>
                <div className='text-4xl'>:</div>
              </>
            )}
            <div className='text-4xl'>{timerMinutes}</div>
            <div className='text-4xl'>:</div>
            <div className='text-4xl'>{timerSeconds}</div>
          </div>
        </div>
      )}
    </div>
  </div>
}

export const TimerPage = () => {
  const { setDefault, setShouldSetDefaultEventState, shouldSetDefaultEventState, activePhasePod } = useEventStore();

  const {entityId, eventId } = Route.useParams();

  const { data: eventData } = useQuery(eventQueryOptions(eventId));

  const activity = eventData?.data?.activities?.[0];
  const { data: activityData } = useQuery({
    queryKey: [activity?.id, 'activity'],
    queryFn: async () => {
      if (!activity?.id || !activity?.gameId) return null;

      try {
        const res = await webAxiosInstance.get(`/api/play/activities/${activity.id}`, {
          headers: {
            'Game-Id': activity.gameId,
          },
        });

        if (shouldSetDefaultEventState) {
          setDefault(res?.data?.data, eventData?.data, activePhasePod?.id);
        } else {
          setShouldSetDefaultEventState(true);
        }
        return res?.data?.data;
      } catch (e) {
        throw new Error(e);
      }
    },
    enabled: Boolean(activity?.id && activity?.gameId)
  });

  const timers = _.reduce(activityData?.phases, (timers, aps) => {
    if (aps?.[0]?.status !== 'inProgress') return timers;
    if (['draft', 'deckbuilding'].includes(aps?.[0]?.activityPhaseType)) {
      timers.push({
        name: aps?.[0].name,
        stage: aps?.[0].stage,
        activityPhaseType: aps?.[0].activityPhaseType,
        duration: aps?.[0].configuration.duration,
        startTime: aps?.[0].startTime,
        endTime: dayjs(aps?.[0].startTime).add(aps?.[0].configuration.duration, 'minutes'),
      })
    }
    if (aps?.[0]?.activityPhaseType === 'tournament') {
      for (const ap of aps) {
        const currentRound = ap.tournament.rounds.find(tr => {
          return tr.roundNumber === ap.tournament.currentRound
        })
        timers.push({
          name: ap.name,
          stage: ap.stage,
          pod: ap.pod,
          activityPhaseType: ap.activityPhaseType,
          roundNumber: currentRound.roundNumber,
          duration: ap.configuration.duration,
          startTime: currentRound.startTime,
          endTime: currentRound.endTime,
        })
      }
    }

    return [...timers, ...timers]
  }, [])

  const hideHeader = true;

  return <div className='flex flex-col gap-4 mt-24'>
     <div className='flex gap-2'>
      {!hideHeader && <div className='ml-2'>
        <Link href={`/entity/${entityId}/tools/events/${eventId}`}> Back to Event</Link>
      </div>}
      <div className='flex-1 text-center text-5xl'>
        {eventData?.data?.name} - {activity?.name} - Stage: {timers?.[0]?.stage}
      </div>
    </div>
    <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)]'>
      <div className={cn('p-8 w-full h-full', {
        'flex items-center justify-center': timers.length === 1,
      })}>
        {timers.length > 1 ? (
          <Carousel className='w-full h-full' opts={{ loop: true, duration: 100 }}>
            <AutoForward intervalTime={5000} />
            <CarouselContent className='w-full h-full'>
              {timers.length > (ELEMENTS_PER_PAGE * 2) ? _.chunk(timers, ELEMENTS_PER_PAGE).map((timerGroup, groupIndex) => (
                <CarouselItem className='w-full h-full' key={groupIndex}>
                  <div className='grid grid-cols-4 grid-rows-2 gap-4 auto-rows-fr w-full h-full'>
                    {timerGroup.map((timer) => (
                      <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
                    ))}
                  </div>
                </CarouselItem>
              )) : (
                timers.map((timer) => {
                  return <CarouselItem key={timer.name}> 
                    <IndividualTimer key={timer.name} timer={timer} isOnTimerPage /> 
                  </CarouselItem>
                })
              )}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        ) : (
          timers.map((timer) => {
            return <IndividualTimer key={timer.name} timer={timer} isOnTimerPage />
          })
        )}
      </div>
    </div>
  </div>
}
