import QRCode from "react-qr-code";
import FlyerHeader from "./FlyerHeader";

const scaleSize = (scale) => {
  if (scale < 0.2) return {
    scanText: 'text-3xs',
    qrCode: 'w-[30%]',
  };

  if (scale < 0.4) return {
    scanText: 'text-3xs',
    qrCode: 'w-[30%] pl-2',
  };

  if (scale < 0.6) return {
    scanText: 'text-2xs',
    qrCode: 'w-[25%] pl-4',
  };

  if (scale < 0.8) return {
    scanText: 'text-sm',
    qrCode: 'w-[22%] pl-4',
  };

  return {
    scanText: 'text-lg',
    qrCode: 'w-96 h-96',
  };
};

const QrCodeSection = ({ event, activity, scale = 1, printRef, game, withHeader = false }) => {
  let environment = "production";
  const origin = location.origin;
  if (
    origin.includes("localhost:") ||
    origin.includes(".dev.") ||
    origin.includes("dev.")
  ) {
    environment = "development";
  }

  const gameUrl = game?.settings
    ?.find((setting) => setting.settingKey?.key === 'gameUrls')
    ?.value
    ?.find((v) => v.environment === environment)
    ?.url;

  const returnTo = (window.location.href?.includes('dashboard.carde.io') ? gameUrl : window.location.href) || window.location.href;

  return (
    <div className={`bg-white p-8 h-fit max-w-[420px]`} ref={printRef}>
      {withHeader && <FlyerHeader event={event} activity={activity} scaleSize={scaleSize} scale={scale} />}
      <div className='flex flex-col items-center px-1 py-2 rounded-lg'>
        <QRCode
          className={`${scaleSize(scale).qrCode} h-full w-full`}
          value={`${import.meta.env.VITE_WEB_URL}/redirect?params=path||to:${import.meta.env.VITE_WEB_URL}/checkout/events/${event.id},query||returnTo:${returnTo},query||origin:direct,query||source:printoutFlyerQrCode`}
        />
        <p className={`${scaleSize(scale).scanText} mt-2 text-zinc-900 text-lg font-black text-center uppercase tracking-[-1px]`}>
          Scan to Join
        </p>
      </div>
    </div>
  );
};

export default QrCodeSection; 