import { startTransition, useEffect, useState } from "react";
import { isEmpty, keys, omit } from "lodash";
import { useParams } from "@tanstack/react-router";
import { RiFilter3Line } from "@remixicon/react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Link } from "@tanstack/react-router";
import Cookies from "js-cookie";

import { findOrganizationById } from "@/utils/organization/findOrganizationById";
import { findEntityById } from "@/utils/entity/findEntityById";

import OrganizationPopoverMenu from "./OrganizationPopoverMenu";
import EntityPopoverMenu from "./EntityPopoverMenu";
import { useRootStore } from "@/store/useRootStore";
import Breadcrumbs from "@/components/Breadcrumbs";
import { findOrganizationByEntityId } from "@/utils/entity/allOrgEntities";
import { Modal, ModalTrigger } from "@repo/ui/modal";
import { Button } from "@repo/ui/button";
import CreateEventModal from "@/components/Modal/Event/CreateEvent";
import { Input } from "@repo/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/popover";
import { GameTypes } from "@/utils/constants/game";
import { useEstablishmentGames } from "@/lib/hooks/useAllGames";
import { useGameStore } from "@/store/useGameStore";
import { useQuery } from "@tanstack/react-query";
import { webAxiosInstance } from '@/utils/axios';


const setDefaultSelectedOrg = (selectedOrg, userData, orgId) => {
  let updatedOrg = selectedOrg;
  if (orgId !== selectedOrg?.id) {
    updatedOrg = findOrganizationById(userData?.organizations, orgId);
    return updatedOrg;
  }
};

const setDefaultEntity = (selectedEntity, entityId, selectedOrg) => {
  let updatedEntity = selectedEntity;
  if (entityId !== selectedEntity?.id && selectedOrg?.id) {
    updatedEntity = findEntityById(selectedOrg?.entities, entityId);
    return updatedEntity;
  }
};

const setDefaultPages = (
  selectedOrg,
  selectedEntity,
  userData,
  orgId,
  entityId
) => {
  const pagesToUpdate = [];
  pagesToUpdate.push({
    name:
      findOrganizationById(userData?.organizations, orgId)?.name ||
      "Organization",
    title: "Organization",
    href: `/org/${orgId}`,
    current: !entityId,
    component: (
      <OrganizationPopoverMenu
        items={userData?.organizations}
        selectedOrg={selectedOrg}
      />
    ),
  });

  if (selectedOrg) {
    pagesToUpdate.push({
      name: selectedEntity?.name || "Entity",
      title: `Entity / ${selectedEntity?.type}`,
      href: `/entity/${entityId}`,
      current: true,
      component: (
        <EntityPopoverMenu
          items={selectedOrg?.entities}
          selectedEntity={selectedEntity}
          orgId={selectedOrg?.id}
        />
      ),
    });
  }

  return pagesToUpdate;
};

const AdminAuthHeaderContainer = () => {
  let hasToolbar = false;
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const entityId = useParams({
    from: "/_authenticated",
    select: (params) => params.entityId,
    strict: false,
  });
  const orgIdFromParams = useParams({
    from: "/_authenticated",
    select: (params) => params.orgId,
    strict: false,
  });
  const allCookies = Cookies.get();
  const spoofEmail = allCookies?.["spoof-user-email"];

  const { userData } = useRootStore();
  const establishment = userData?.establishments?.find(
    (establishment) => establishment.entityId === entityId
  );
  const orgId =
    orgIdFromParams ||
    findOrganizationByEntityId(userData?.organizations, entityId)?.id;

  const {data: orgData} =  useQuery({
    queryKey: ["organization", orgId],
    queryFn: async () => {
      return await webAxiosInstance.get(`/api/manage/organizations/${orgId}`)
    },
    enabled: Boolean(orgId)
  })

  const [selectedOrg, setSelectedOrg] = useState(
    setDefaultSelectedOrg(null, userData, orgId)
  );
  const [selectedEntity, setSelectedEntity] = useState(
    setDefaultEntity(null, entityId, selectedOrg)
  );
  const [pages, setPages] = useState(
    setDefaultPages(
      setDefaultSelectedOrg(null, userData, orgId),
      setDefaultEntity(null, entityId, selectedOrg),
      userData,
      orgId,
      entityId
    )
  );
  useEstablishmentGames();

  const { setEntityFilterName } = useGameStore.getState();
  useEffect(() => {
    let updatedOrg = selectedOrg;
    if (orgId !== selectedOrg?.id) {
      updatedOrg = findOrganizationById(userData?.organizations, orgId);
      setSelectedOrg(updatedOrg);
    }

    if (entityId !== selectedEntity?.id && updatedOrg?.id) {
      setSelectedEntity(findEntityById(updatedOrg?.entities, entityId));
    }
  }, [orgId, entityId, userData, selectedOrg, selectedEntity?.id]);

  
  const organizations = userData?.organizations
  const entities = selectedOrg?.entities;

  useEffect(() => {
    const pagesToUpdate = [];

    pagesToUpdate.push({
      name:
        findOrganizationById(userData?.organizations, orgId)?.name ||
        "Organization",
      title: "Organization",
      href: `/org/${orgId}`,
      current: !entityId,
      component: organizations.length > 1 ? <OrganizationPopoverMenu items={organizations} selectedOrg={selectedOrg} /> : null
    })

    if (selectedOrg) {
      pagesToUpdate.push({
        name: selectedEntity?.name || "Entity",
        title: `Entity / ${selectedEntity?.type}`,
        href: `/entity/${entityId}`,
        current: true,
        component: entities.length > 1 ? <EntityPopoverMenu items={entities} selectedEntity={selectedEntity} orgId={selectedOrg?.id} /> : null
      })
    }

    if (location.pathname.includes("/tools")) {
      if (location.pathname.includes("/tools/")) {
        const tool = location.pathname.split("/tools/")[1].split("/")[0];
        pagesToUpdate.push({
          name: tool || "Tools",
          title: `Tools / ${tool}`,
          href: `/entity/${entityId}/tools/${tool}`,
          current: true,
          component: (
            <Link
              to={`/entity/${entityId}/tools/${tool}`}
              className="flex flex-col"
            >
              <div className="flex -mb-1">
                <p className="font-light uppercase text-3xs text-zinc-300">
                  Tools
                </p>
              </div>
              <div className="flex -mb-2">
                <span className="max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                  {tool}
                </span>
              </div>
            </Link>
          ),
        });
      } else {
        pagesToUpdate.push({
          name: "Tools",
          title: `Tools`,
          href: "",
          current: true,
          component: (
            <div className="flex flex-col">
              <div className="flex mt-4">
                <span className="max-w-[250px] whitespace-nowrap overflow-hidden text-ellipsis capitalize">
                  Tools
                </span>
              </div>
            </div>
          ),
        });
      }
    }

    setPages(pagesToUpdate);
  }, [selectedOrg, selectedEntity, userData, orgId, entityId, organizations, entities]);

  if (establishment?.id) {
    const splitPath = location.pathname.split("/entity/")[1];
    if (
      !splitPath.includes("/") ||
      location.pathname.includes("/tools/games")
    ) {
      hasToolbar = true;
    }
  }

  const { reset, control } = useForm({
    defaultValues: {
      entityFilterName: "",
      gameTypes: [],
    },
  });

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const formValues = useWatch({ control });

  useEffect(() => {
    let updatedValues = { ...formValues };
    ["entityFilterName", "gameTypes"].forEach((gameType) => {
      if (isEmpty(updatedValues[gameType])) {
        updatedValues = omit(updatedValues, [gameType]);
      }
    });
    startTransition(() => {
      setEntityFilterName(updatedValues.entityFilterName);
    });
  }, [formValues, setEntityFilterName]);

  const AddEventModal = () => {
    return (
      <div className="flex-shrink-0 mr-12">
        <Modal
          open={isCreateEventModalOpen}
          onOpenChange={setIsCreateEventModalOpen}
        >
          <ModalTrigger asChild>
            <Button
              variant="gradient-contained"
              rounded
              style={{ padding: "0.5rem 1rem", fontSize: "0.875rem" }}
            >
              Create Event
            </Button>
          </ModalTrigger>
          <CreateEventModal
            handleClose={() => setIsCreateEventModalOpen(false)}
          />
        </Modal>
      </div>
    );
  };

  const unspoofUser = () => {
    Cookies.remove("spoof-user-email");
    window.location.href = "/";
  };

  return (
    <div
      className={`py-12 animate-slideInFromTop flex justify-between fixed top-0 z-20 w-screen -ml-[7.5rem] pl-[7.5rem] transition-all ${isScrolled ? "bg-zinc-950" : ""}`}
    >
      <div className={`flex flex-col gap-2 mx-8${spoofEmail ? " -mt-6" : ""}`}>
        {spoofEmail && (
          <div className={"flex items-center "}>
            <div className="font-bold mr-1 text-xs">Spoofed User:</div>
            <div className="font-bold mr-4 text-2xs">{spoofEmail}</div>
            <Button onClick={unspoofUser} size="xs">
              Unspoof
            </Button>
          </div>
        )}
        {(organizations?.length > 1 && entities?.length > 1 || orgData?.data?.fullAdminView) && <Breadcrumbs organizationId={orgId} entityId={entityId} pages={pages} />}
      </div>

      {hasToolbar && (
        <div className="flex gap-2 items-center">
          <Controller
            control={control}
            name={"entityFilterName"}
            render={({ field: { value, onChange } }) => (
              <Input
                className={"bg-primary"}
                value={value}
                placeholder={"Search Games"}
                onChange={onChange}
              />
            )}
          />
          <Popover>
            <PopoverTrigger>
              <Button size="sm">
                <div className="relative">
                  <RiFilter3Line className="h-5 w-5" />
                </div>
              </Button>
            </PopoverTrigger>
            <PopoverContent className={"bg-primary w-[300px]"}>
              <div className={"flex flex-col"}>
                <div className={"self-end"}>
                  <button className={"w-auto"} onClick={() => reset()}>
                    Reset
                  </button>
                </div>
                <div>
                  <h4 className="text-lg font-bold mb-2">Game Types</h4>
                  <Controller
                    control={control}
                    name={"gameTypes"}
                    render={({ field: { value, onChange } }) => {
                      let selectedValues = new Set(value);
                      return (
                        <div
                          className={"mt-1 flex justify-around gap-2 flex-wrap"}
                        >
                          {keys(GameTypes).map((type) => (
                            <Button
                              key={type}
                              size={"xs"}
                              onClick={() => {
                                selectedValues.has(type)
                                  ? selectedValues.delete(type)
                                  : (selectedValues = new Set([type]));
                                onChange(Array.from(selectedValues));
                              }}
                              variant={
                                selectedValues.has(type)
                                  ? "gradient-contained"
                                  : "outlined"
                              }
                            >
                              {GameTypes[type]}
                            </Button>
                          ))}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </PopoverContent>
          </Popover>
          <AddEventModal />
        </div>
      )}
    </div>
  );
};

export default AdminAuthHeaderContainer;
