import { useState } from "react";
import { toast } from "sonner";
import { useMutation } from "@tanstack/react-query";

import { SheetHeader } from "@repo/ui/sheet";
import { Button } from "@repo/ui/button";
import { updateRoundTimerMutationFn } from "@repo/api/base";
import { webAxiosInstance } from "@/utils/axios";
import { useEventStore } from "@/store/useEventStore";
import {
  getFormattedDateTimeFromNow,
  getTimeFromNowInHours,
  getTimeFromNowInMinutes,
  getTimeFromNowInSeconds,
} from "@repo/utils/date";
import ManageTime from "@/components/Sheets/Timer/ManageTime";
import { Link } from "@tanstack/react-router";


const ManageRoundTimerSheet = ({ handleClose }) => {
  const { currentTournamentRound, refetch, activity } = useEventStore();

  const [hoursRemaining, setHoursRemaining] = useState(getTimeFromNowInHours(currentTournamentRound?.endTime) < 0 ? 0 : getTimeFromNowInHours(currentTournamentRound?.endTime));
  const [minutesRemaining, setMinutesRemaining] = useState(getTimeFromNowInMinutes(currentTournamentRound?.endTime) < 0 ? 0 : getTimeFromNowInMinutes(currentTournamentRound?.endTime));
  const [secondsRemaining, setSecondsRemaining] = useState(getTimeFromNowInSeconds(currentTournamentRound?.endTime) < 0 ? 0 : getTimeFromNowInSeconds(currentTournamentRound?.endTime));

  const updateTimerMutation = useMutation({
    mutationFn: updateRoundTimerMutationFn,
    onSuccess: () => {
      refetch();
      handleClose && handleClose();
      toast('Successfully Update Event Flyer!');
    },
    onError: () => {
      toast("We apologize for this inconvenience.", {
        description: "There was an error with editing this flyer. Please reach out to support. We look forward to hearing from you!",
        action: {
          label: "Contact Support",
          onClick: () => window.location.href = 'https://carde.io/support',
        },
      })
    },
  });

  const handleUpdateTimer = () => {
    updateTimerMutation.mutate({
      clientAxiosInstance: webAxiosInstance,
      tournamentRoundId: currentTournamentRound.id,
      endTime: getFormattedDateTimeFromNow(hoursRemaining, minutesRemaining, secondsRemaining),
    });
  }

  return (
    <div className='flex flex-col justify-between h-full gap-8 overflow-scroll scrollbar-hide scrollbar-hide'>
      <SheetHeader>
        <h2 className='text-3xl font-bold'>Manage Timer</h2>
      </SheetHeader>

      <div className='flex w-full items-center justify-between'>
        <ManageTime label='Hours' min={0} max={99} onChange={setHoursRemaining} value={hoursRemaining} />
        <ManageTime label='Minutes' onChange={setMinutesRemaining} value={minutesRemaining} />
        <ManageTime label='Seconds' onChange={setSecondsRemaining} value={secondsRemaining} />
      </div>

      <div className='flex flex-col items-center gap-4 w-full'>
        <Link to={`${window.location.pathname}/activity/${activity?.id}/timer`} target="_blank">Open FullScreen Timer</Link>
        <Button
          className='w-full'
          onClick={handleUpdateTimer}
          variant='gradient-contained'
        >
          Update Timer
        </Button>
      </div>
    </div>
  )
};

export default ManageRoundTimerSheet;
