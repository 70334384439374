type activitiesFinalizeAllPreviewsMutationFnParams = {
  clientAxiosInstance: any;
  activityId: any;
};

export const activitiesFinalizeAllPreviewsMutationFn = async ({
  clientAxiosInstance,
  activityId,
}: activitiesFinalizeAllPreviewsMutationFnParams) => {
  return clientAxiosInstance.post(
    `/api/organize/activities/${activityId}/finalizeAllPreviews`,
    {},
  );
};
